// @ts-check

/***********************************************************************************************************************
* BE CAREFUL: Action code are used to generate Cypress selectors, if you change it check if this code is used in tests *
***********************************************************************************************************************/

import fileDownload from 'js-file-download'
import _ from 'lodash'
import moment from 'moment'
import VueInstance from '@/main'
import Action from './action'
import IncExc from './inc-exc'
import Scope from './scope'
import Search, { MAX_SELECTED_ITEMS } from './search'
import navigationController from '@/navigationController'
import recentsController from '@/recentsController'
import tabController from '@/tabController'
import { debugMemoryCleanUp } from '@/debugger'

/**
 * @typedef {import('./search').SearchAction} SearchAction
 * @typedef {import('./item-list').Item} Item
 * @typedef {import('./item-list').RawItem} RawItem
 */

/**
 * @callback ActionHandler
 * @param {{values?: Array, item?: Item, search?: Search}} params
 * @returns {Promise}
 */

let riskSettings = {}
VueInstance.$http().get('/core/v6/settings/risk-category').then(({ data }) => { riskSettings = data })
export default class ActionManager {
  #knownActions = new Map()
  #knownHandlers = new Map()
  #logRun
  #search

  /**
   * @param {Search} search
   * @param {boolean} runLog
   */
  constructor(search, runLog) {
    debugMemoryCleanUp(this, `action manager for search ${search.searchId}`)
    this.#search = search
    this.#logRun = runLog
  }

  /**
   * @private
   * @param {'hard'|'restore'|'soft'} mode
   * @returns {ActionHandler}
   */
  _buildDeleteAction (mode) {
    return this._getOrCreateHandler(
      `delete:${mode}`,
      params => this._searchActionBuilder(Search.actions.DELETE, { mode }, params)
    )
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildEndEscalationAction () {
    return this._getOrCreateHandler(
      'end-escalation',
      params => this._searchActionBuilder(
        Search.actions.END_ESCALATION_PROTOCOLS,
        {
          clearHistory: params.values[0],
          selectedTy: this.#search.documentType
        },
        params
      )
    )
  }

  /**
* @private
* @returns {ActionHandler}
*/
  _buildExecuteProcesses () {
    return this._getOrCreateHandler(
      'execute-processes',
      params => {
        return this._searchActionBuilder(Search.actions.EXECUTE_PROCESSES, {}, params)
      }
    )
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildDisputeAction () {
    return this._getOrCreateHandler('dispute', params => {
      const invoices = params?.item ? [params?.item] : this.#search.selectedItems.includeItems
      // @ts-ignore
      recentsController.addTab(
        tabController.create(
          tabController.disputesTab(),
          { invoices: invoices.map(i => ({ ...i.extra, id: i.id })) }
        ),
        {
          documentType: 'accounts',
          documentId: this._getAccountId(invoices)
        }
      )
      return Promise.resolve({ isSilent: true })
    })
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildDownloadLetterAction () {
    const type = this.#search.documentType
    return this._getOrCreateHandler(
      `download-letters:${type}`,
      params => this._searchActionBuilder(Search.actions.DOWNLOAD_LETTERS, {}, params, { responseType: 'blob' })
        .then(data => {
          const fileType = type === 'dunning-reminders' ? VueInstance.$t('t.Dunnings') : VueInstance.$t('t.Reminders')
          fileDownload(data, `${fileType}_${moment().format('YYYY-MM-DD')}.zip`)
          return { isSilent: true }
        })
    )
  }

  /**
 * @private
 * @returns {ActionHandler}
 */
  _buildSendAr24Message () {
    return this._getOrCreateHandler(
      'send-ar24-message',
      params => this._searchActionBuilder(Search.actions.SEND_AR24_MESSAGE, { target: { userId: params.values[0] } }, params)
    )
  }

  /**
 * @private
 * @returns {ActionHandler}
 */
  _buildSyncAr24Message () {
    return this._getOrCreateHandler(
      'sync-ar24-message',
      params => {
        return this._searchActionBuilder(Search.actions.SYNC_AR24_MESSAGE, { target: {} }, params)
      }
    )
  }

  /**
* @private
* @returns {ActionHandler}
*/
  _buildSendMailevaLetter () {
    return this._getOrCreateHandler(
      'send-maileva-letter',
      params => this._searchActionBuilder(Search.actions.SEND_MAILEVA_LETTER, { target: {} }, params)
    )
  }

  /**
* @private
* @returns {ActionHandler}
*/
  _buildSyncMailevaLetter () {
    return this._getOrCreateHandler(
      'sync-maileva-letter',
      params => {
        return this._searchActionBuilder(Search.actions.SYNC_MAILEVA_LETTER, { target: {} }, params)
      }
    )
  }

  /**
   * @private
   * @param {boolean} link
   * @param {boolean} [grantUsersOf = undefined]
   * @returns {ActionHandler}
   */
  _buildLinkAction (link, grantUsersOf = undefined) {
    return this._getOrCreateHandler(
      `link:${link}:${grantUsersOf}`,
      params => this._searchActionBuilder(
        Search.actions.LINKING,
        {
          mode: link ? 'link' : 'unlink',
          grantUsersOf,
          target: this.#search.linkTarget
        },
        params
      )
    )
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildMessageAction () {
    return this._getOrCreateHandler('message', params => {
      const invoices = params?.item ? [params?.item] : this.#search.selectedItems.includeItems
      // @ts-ignore
      recentsController.addTab(
        tabController.create(
          tabController.newMessageTab(),
          {
            invoiceIds: invoices.map(i => i.id),
            templateId: params.values[0]
          }
        ),
        {
          documentType: 'accounts',
          documentId: this._getAccountId(invoices)
        }
      )
      return Promise.resolve({ isSilent: true })
    })
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildPreviewAction () {
    return this._getOrCreateHandler(
      'preview',
      params => {
        this.#search.setPreviewDocument({ id: params.item.id, type: params.item.type }).showPreviewPopup = true
        return Promise.resolve({ isSilent: true })
      }
    )
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildPromiseAction () {
    return this._getOrCreateHandler('promise', params => {
      const invoices = params?.item ? [params?.item] : this.#search.selectedItems.includeItems
      // @ts-ignore
      recentsController.addTab(
        tabController.create(
          tabController.promisesTab(),
          { invoices: invoices.map(i => ({ ...i.extra, id: i.id })) }
        ),
        {
          documentType: 'accounts',
          documentId: this._getAccountId(invoices)
        }
      )
      return Promise.resolve({ isSilent: true })
    })
  }

  /**
   * @private
   * @param {'escalation-protocols'|'processes'} type
   * @returns {ActionHandler}
   */
  _buildReorder (type) {
    return this._getOrCreateHandler(
      `reorder:${type}`,
      params => this._searchActionBuilder(
        Search.actions.REORDER,
        {
          direction: params.values[0],
          target: {
            id: params.values[1],
            type
          }
        },
        params
      )
    )
  }

  /**
   * @private
   * @param {'businessDivisionId'|'comment'|'collectionSegmentId'|'dunningBlocked'|'excludeFromDso'|
   * 'mainSalesRepId'|'parentId'|'riskMonitored'|'secondSalesRepId'|'workQueueId'} property
   * @param {boolean} [valueOverride]
   * @returns {ActionHandler}
   */
  _buildSetAccountPropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-account-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_ACCOUNT_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
* @private
* @param {'comment'} property
* @param {boolean} [valueOverride]
* @returns {ActionHandler}
*/
  _buildSetAllocationAnnouncementPropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-allocation-announcement-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_ALLOCATION_ANNOUNCEMENT_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
 * @private
 * @param {'comment'} property
  * @param {boolean} [valueOverride]
  * @returns {ActionHandler}
  */
  _buildSetPromisePropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-promise-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_PROMISE_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
* @private
* @param {'comment'} property
* @param {boolean} [valueOverride]
* @returns {ActionHandler}
*/
  _buildSetCollaborationPropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-collaboration-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_COLLABORATION_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
   * @private
   * @param {'isActive'|'isAllowPortalAccess'|'isImportOverwritable'} property
   * @param {boolean} valueOverride
   * @returns {ActionHandler}
   */
  _buildSetAccountContactPropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-account-contact-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_ACCOUNT_CONTACT_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0] } },
        params
      )
    )
  }

  /**
 * @private
 * @returns {ActionHandler}
 */
  _buildSetAccountContactGroupsAction () {
    return this._getOrCreateHandler(
      'set-user-account-contact-groups',
      params => this._searchActionBuilder(
        Search.actions.SET_ACCOUNT_CONTACT_GROUPS,
        { target: params.values[0] },
        params
      )
    )
  }

  /**
 * @private
 * @param {'comment'} property
 * @param {boolean} [valueOverride]
 * @returns {ActionHandler}
 */
  _buildSetDisputePropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-dispute-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_DISPUTE_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
   * @private
   * @param {'excludeFromDunning'|'transactionTypeId'|'businessDivisionId'|'comment'} property
   * @param {boolean} [valueOverride]
   * @returns {ActionHandler}
   */
  _buildSetInvoicePropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-invoice-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_INVOICE_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0], removeOldComment: params.values[1] ?? false } },
        params
      )
    )
  }

  /**
 * @private
 * @returns {ActionHandler}
 */
  _buildSetLabelsLinkAction () {
    return this._getOrCreateHandler(
      'set-labels-link',
      params => this._searchActionBuilder(
        Search.actions.SET_LABELS_LINK,
        { target: { labelIds: params.values[0], backupMode: params.values[1] ? 'REMOVE' : 'ADD' } },
        params
      )
    )
  }

  /**
   * @private
   * @param {'proposed'|'excluded'|'rejected'} status
   * @returns {ActionHandler}
   */
  _buildSetReminderStatusAction (status) {
    return this._getOrCreateHandler(
      `set-reminder-status:${status}`,
      params => this._searchActionBuilder(Search.actions.SET_REMINDER_STATUS, { target: status }, params)
    )
  }

  /**
   * @private
   * @param {boolean} isClosed
   * @returns {ActionHandler}
   */
  _buildSetTopicPropertiesAction (isClosed) {
    return this._getOrCreateHandler(
      `set-topic-properties:${isClosed}`,
      params => this._searchActionBuilder(Search.actions.SET_TOPIC_PROPERTIES, { target: { isClosed } }, params)
    )
  }

  /**
     * @private
     * @param {'isActive'|'isImportOverwritable'} property
     * @param {boolean} valueOverride
     * @returns {ActionHandler}
     */
  _buildSetUserPropertiesAction (property, valueOverride) {
    return this._getOrCreateHandler(
      `set-user-properties:${property}:${valueOverride}`,
      params => this._searchActionBuilder(
        Search.actions.SET_USER_PROPERTIES,
        { target: { [property]: valueOverride ?? params.values[0] } },
        params)
    )
  }

  /**
   * @private
   * @param {'close'|'reassign'|'report'} updateAction
   * @returns {ActionHandler}
   */
  _buildSetWorkItemsAction (updateAction) {
    return this._getOrCreateHandler(
      `set-work-items:${updateAction}`,
      params => {
        let target
        switch (updateAction) {
          case 'close':
            target = { comment: params.values[0] }
            break
          case 'reassign':
            target = {
              comment: params.values[1],
              userId: params.values[0]
            }
            break
          case 'report':
            target = {
              comment: params.values[4],
              isBlockingProtocol: params.values[3],
              priority: params.values[1],
              targetDate: moment(params.values[0]).format('YYYY-MM-DDTHH:mm:ss'),
              userId: params.values[2]
            }
            break
        }
        Object.assign(target, { updateAction })
        return this._searchActionBuilder(Search.actions.SET_WORK_ITEMS, { target }, params)
      }
    )
  }

  /**
   * @private
   * @returns {ActionHandler}
   */
  _buildTopicAction () {
    return this._getOrCreateHandler('topic', params => {
      const items = params?.item ? [params?.item] : this.#search.selectedItems.includeItems
      const accountId = this._getAccountId(items)
      // @ts-ignore
      recentsController.addTab(
        tabController.create(
          tabController.topicsTab(),
          { links: items.map(i => ({ id: { id: i.id, type: i.type }, grantUsersOf: true })) }
        ),
        accountId
          ? { documentType: 'accounts', documentId: accountId }
          : { documentType: 'topics' }
      )
      return Promise.resolve({ isSilent: true })
    })
  }

  /**
   * Return an accountId
   * @private
   * @param {Array.<RawItem>} items
   * @returns {string}
   */
  _getAccountId (items) {
    return this.#search.scope.accountId ?? items[0]?.extra?.accountId
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableAccountActions () {
    const actions = new ActionList()

    if (VueInstance.$store.getters.currentUserHasPermission('ManualParentAccounts')) {
      actions.add(
        new Action(
          'set-parent-id',
          this._buildSetAccountPropertiesAction('parentId'),
          VueInstance.$t('t.ParentItem'),
          VueInstance.$t('t.TheAccount')
        )
          .setCategory(Action.category.ASSIGNMENT)
          .addAcceptedValue({
            type: { isRequired: false, documentType: 'accounts' },
            label: VueInstance.$t('t.Accounts')
          })
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountCanChangeDivision')) {
      actions.add(new Action(
        'set-account-business-division',
        this._buildSetAccountPropertiesAction('businessDivisionId'),
        VueInstance.$t('t.Division'),
        VueInstance.$t('t.TheDivision')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'business-divisions' },
          label: VueInstance.$t('t.Division')
        })
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountEdit')) {
      actions.add(new Action(
        'set-account-comment',
        this._buildSetAccountPropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
      actions.add(new Action(
        'set-collection-segment',
        this._buildSetAccountPropertiesAction('collectionSegmentId'),
        VueInstance.$t('t.CollectionSegment'),
        VueInstance.$t('t.TheCollectionSegment')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'collection-segments' },
          label: VueInstance.$t('t.CollectionSegment')
        })
      )
      actions.add(new Action(
        'set-main-sales-rep',
        this._buildSetAccountPropertiesAction('mainSalesRepId'),
        VueInstance.$t('t.MainSalesRep'),
        VueInstance.$t('t.TheMainSalesRep')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { documentType: 'users', filters: { roles: new IncExc().include(['sales-reps']) } },
          label: VueInstance.$t('t.MainSalesRep'),
          placeholder: VueInstance.$t('t.None')
        })
      )
      actions.add(new Action(
        'set-second-sales-rep',
        this._buildSetAccountPropertiesAction('secondSalesRepId'),
        VueInstance.$t('t.SecondSalesRep'),
        VueInstance.$t('t.TheSecondSalesRep')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { documentType: 'users', filters: { roles: new IncExc().include(['sales-reps']) } },
          label: VueInstance.$t('t.SecondSalesRep'),
          placeholder: VueInstance.$t('t.None')
        })
      )
      actions.add(new Action(
        'set-work-queue',
        this._buildSetAccountPropertiesAction('workQueueId'),
        VueInstance.$t('t.WorkQueue'),
        VueInstance.$t('t.TheWorkQueue')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'work-queues' },
          label: VueInstance.$t('t.WorkQueue')
        })
      )
      actions.add(new Action(
        'exclude-from-dso',
        this._buildSetAccountPropertiesAction('excludeFromDso', true),
        VueInstance.$t('t.ExcludeFromDSO'),
        VueInstance.$t('t.ExcludeFromDSO')
      )
        .setCategory(Action.category.DSO)
        .cannotBeQuick()
      )
      actions.add(new Action(
        'include-from-dso',
        this._buildSetAccountPropertiesAction('excludeFromDso', false),
        VueInstance.$t('t.IncludeToDSO'),
        VueInstance.$t('t.IncludeToDSO')
      )
        .setCategory(Action.category.DSO)
        .cannotBeQuick()
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountCanSwitchDunningState')) {
      actions.add(new Action(
        'activate-dunning',
        this._buildSetAccountPropertiesAction('dunningBlocked', false),
        VueInstance.$t('t.EnableDunning'),
        VueInstance.$t('t.TheDunning')
      )
        .setCategory(Action.category.DUNNING)
        .cannotBeQuick()
      )
      actions.add(new Action(
        'deactivate-dunning',
        this._buildSetAccountPropertiesAction('dunningBlocked', true),
        VueInstance.$t('t.DisableDunning'),
        VueInstance.$t('t.TheDunning')
      )
        .setCategory(Action.category.DUNNING)
        .cannotBeQuick()
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountEndEscalation')) {
      actions.add(new Action(
        'end-escalation-account',
        this._buildEndEscalationAction(),
        VueInstance.$t('t.EndEscalation'),
        VueInstance.$t('t.EndEscalation')
      )
        .setCategory(Action.category.DUNNING)
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.ClearHistory')
        })
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountCanChangeRiskMonitoring')) {
      if (riskSettings.type === 2 /* InfoLegale */ || riskSettings.type === 3 /* CreditSafes */) {
        actions.add(new Action(
          'exclude-from-risk',
          this._buildSetAccountPropertiesAction('riskMonitored', false),
          VueInstance.$t('t.ExcludeFromRisk'),
          VueInstance.$t('t.ExcludeFromRisk')
        )
          .setCategory(Action.category.RISK_MONITORED)
          .cannotBeQuick()
        )
        actions.add(new Action(
          'include-from-risk',
          this._buildSetAccountPropertiesAction('riskMonitored', true),
          VueInstance.$t('t.IncludeToRisk'),
          VueInstance.$t('t.IncludeToRisk')
        )
          .setCategory(Action.category.RISK_MONITORED)
          .cannotBeQuick()
        )
      }
    }

    actions.add(new Action(
      'set-account-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.Label'),
      VueInstance.$icon('i.Labels')
    )
      .setCategory(Action.category.CREATE)
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableAccountContactsActions () {
    const actions = new ActionList()
    if (VueInstance.$store.getters.currentUserHasPermission('AccountContactsShowBulkAction')) {
      actions.add(new Action(
        'deactivate-contact',
        this._buildSetAccountContactPropertiesAction('isActive', false),
        VueInstance.$t('t.Deactivate'),
        VueInstance.$t('t.Deactivate')
      )
        .setCategory(Action.category.ACTIVATION)
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.isActive)
      )
      actions.add(new Action(
        'allow-portal-access',
        this._buildSetAccountContactPropertiesAction('isAllowPortalAccess', true),
        VueInstance.$t('t.Activate'),
        VueInstance.$t('t.Activate')
      )
        .setCategory(Action.category.PORTAL)
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.canAllowPortal)
      )
      actions.add(new Action(
        'deny-portal-access',
        this._buildSetAccountContactPropertiesAction('isAllowPortalAccess', false),
        VueInstance.$t('t.Deactivate'),
        VueInstance.$t('t.Deactivate')
      )
        .setCategory(Action.category.PORTAL)
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.canDenyPortal)
      )
      actions.add(new Action(
        'contact-is-import-overwritable',
        this._buildSetAccountContactPropertiesAction('isImportOverwritable', true),
        VueInstance.$t('t.IsImportOverwritable'),
        VueInstance.$t('t.IsImportOverwritable')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => !a.extra.isImportOverwritable)
      )
      actions.add(new Action(
        'contact-is-not-import-overwritable',
        this._buildSetAccountContactPropertiesAction('isImportOverwritable', false),
        VueInstance.$t('t.IsNotImportOverwritable'),
        VueInstance.$t('t.IsNotImportOverwritable')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.isImportOverwritable)
      )

      if (VueInstance.$store.getters.isMasterAdmin()) {
        actions.add(new Action(
          'delete-account-contact',
          this._buildDeleteAction('hard'),
          VueInstance.$t('t.DeletePermanently'),
          VueInstance.$t('t.DeletePermanently'),
          VueInstance.$icon('i.HardDelete')
        )
          .cannotBeQuick()
          .addRule(a => !a.extra.isActive)
          .setSelectionMustBeCleared()
        )
      }

      actions.add(new Action(
        'set-account-contact-labels-link',
        this._buildSetLabelsLinkAction(),
        VueInstance.$t('t.Label'),
        VueInstance.$t('t.Label'),
        VueInstance.$icon('i.Labels')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'label',
          label: VueInstance.$t('t.Label')
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.Delete')
        })
      )
    }
    return actions
  }

  /**
  * @private
  * @returns {ActionList}
  */
  _getAvailableUserAccountsActions () {
    const actions = new ActionList()
    if (VueInstance.$store.getters.currentUserHasPermission('AccountContactsShowBulkAction')) {
      actions.add(new Action(
        'set-account-contact-groups',
        this._buildSetAccountContactGroupsAction(),
        VueInstance.$t('t.UpdateContactGroup'),
        VueInstance.$t('t.UpdateContactGroup')
      )
        .cannotBeQuick()
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'contact-groups' },
          label: VueInstance.$t('t.ContactGroup')
        })
      )
    }
    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableDisputeActions () {
    const actions = new ActionList()
    actions.add(new Action(
      'download-attachments',
      this._getOrCreateHandler('download-dispute-attachments', params => {
        const invoices = params?.item ? [params?.item] : this.#search.selectedItems.includeItems
        // @ts-ignore
        return VueInstance.$http().get('/disputesAttachmentsZip', {
          params: {
            DisputesId: invoices.map(i => i.id),
            download: true
          },
          responseType: 'blob'
        })
          .then(({ data }) => {
            fileDownload(data, 'file.zip')
            return { isSilent: true }
          }).catch(e => e)
      }),
      VueInstance.$t('t.ExtractAndZipAttachments'),
      VueInstance.$t('t.ExtractAndZipAttachments')
    )
    )
    actions.add(new Action(
      'topic-dispute',
      this._buildTopicAction(),
      VueInstance.$t('t.Topic'),
      VueInstance.$t('t.Topic'),
      VueInstance.$icon('i.Topics')
    ).setCategory(Action.category.CREATE)
      .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
      .skipConfirmation()
    )

    actions.add(new Action(
      'set-dispute-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.AddLabels'),
      VueInstance.$icon('i.Labels')
    )
      .setCategory(Action.category.CREATE)
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    if (
      VueInstance.$store.getters.currentUserHasPermission('Disputes')
    ) {
      actions.add(new Action(
        'set-dispute-comment',
        this._buildSetDisputePropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
    }

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableCollaborationTaskActions () {
    const actions = new ActionList()
    actions.add(new Action(
      'topic-collaboration',
      this._buildTopicAction(),
      VueInstance.$t('t.Topic'),
      VueInstance.$t('t.Topic'),
      VueInstance.$icon('i.Topics')
    )
      .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
      .skipConfirmation()
    )

    actions.add(new Action(
      'set-collaboration-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.Label'),
      VueInstance.$icon('i.Labels')
    )
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    if (
      VueInstance.$store.getters.currentUserHasPermission('CollaborationTasks')
    ) {
      actions.add(new Action(
        'set-collaboration-comment',
        this._buildSetCollaborationPropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        // .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
    }

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableColumnsActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'restore-column',
      this._buildDeleteAction('restore'),
      VueInstance.$t('t.UnDelete'),
      VueInstance.$t('t.UnDelete'),
      VueInstance.$icon('i.NotDelete')
    )
      .rulesAreInclusives()
      .addRule(a => a.extra.isDeleted)
    )

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableEscalationProtocolActions () {
    const actions = new ActionList()
    actions.add(new Action(
      'reorder-protocol',
      this._buildReorder('escalation-protocols'),
      VueInstance.$t('t.ReorderEscalationProtocols'),
      VueInstance.$t('t.ReorderEscalationProtocols')
    )
      .addAcceptedValue({
        type: 'select',
        label: VueInstance.$t('t.Direction'),
        values: [
          { text: VueInstance.$t('t.Before'), value: 'before' },
          { text: VueInstance.$t('t.After'), value: 'after' }
        ]
      })
      .addAcceptedValue({
        type: { isRequired: true, documentType: 'escalation-protocols' },
        excluded: () => this.#search.selectedItems.includeItems.map(i => i.id),
        label: VueInstance.$t('t.EscalationProtocol')
      })
    )
    if (VueInstance.$store.getters.currentUserHasPermission('AccountEndEscalation')) {
      actions.add(new Action(
        'end-escalation-protocol',
        this._buildEndEscalationAction(),
        VueInstance.$t('t.ReinitEscalation'),
        VueInstance.$t('t.ReinitEscalation')
      )
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.ClearHistory')
        })
      )
    }
    return actions
  }

  /**
    * @private
    * @returns {ActionList}
    */
  _getAvailableGroupamaRequestActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'topic-groupama',
      this._buildTopicAction(),
      VueInstance.$t('t.Topic'),
      VueInstance.$t('t.Topic'),
      VueInstance.$icon('i.Topics')
    )
      .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
      .skipConfirmation()
    )

    return actions
  }

  /**
   * @private
   * @param {RawItem} item
   * @returns {ActionList}
   */
  _getAvailableInvoiceActions (item) {
    const actions = new ActionList()

    if ([Scope.DISPUTE_INVOICES_ACCOUNT, Scope.PROMISE_INVOICES_ACCOUNT].includes(this.#search.scope?.type)) {
      actions.add(new Action(
        'navigate-invoice',
        this._getOrCreateHandler('navigate', () => {
          // @ts-ignore
          navigationController.navigateTo(item.id, item.type)
          return Promise.resolve({ isSilent: true })
        }),
        VueInstance.$t('t.NavigateTo'),
        VueInstance.$t('t.NavigateTo'),
        VueInstance.$icon('i.GoToDocument')
      ).skipConfirmation()
      )
      return actions
    }

    if (VueInstance.$store.getters.currentUserHasPermission('AccountCanChangeDivision')) {
      actions.add(new Action(
        'set-invoice-business-division',
        this._buildSetInvoicePropertiesAction('businessDivisionId'),
        VueInstance.$t('t.Division'),
        VueInstance.$t('t.TheDivision')
      )
        .setCategory(Action.category.ASSIGNMENT)
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'business-divisions' },
          label: VueInstance.$t('t.Division')
        })
      )
    }

    actions.add(new Action(
      'set-invoice-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.Label'),
      VueInstance.$icon('i.Labels')
    )
      .setCategory(Action.category.CREATE)
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    if (
      VueInstance.$store.getters.currentUserHasPermission('AccountNewDispute') &&
      !isNaN(item.extra?.disputeAmountAllowed) &&
      item.extra.disputeAmountAllowed !== 0
    ) {
      actions.add(new Action(
        'dispute',
        this._buildDisputeAction(),
        VueInstance.$t('t.Dispute'),
        VueInstance.$t('t.Dispute'),
        VueInstance.$icon('i.Disputes')
      )
        .setCategory(Action.category.CREATE)
        .addRule((a, b) => a.extra.currency === b.extra.currency && this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
        .skipConfirmation()
      )
    }

    if (
      VueInstance.$store.getters.currentUserHasPermission('AccountNewPromise') &&
      !isNaN(item.extra?.promiseAmountAllowed) &&
      item.extra?.promiseCount === 0 &&
      item.extra.promiseAmountAllowed !== 0
    ) {
      actions.add(new Action(
        'promise',
        this._buildPromiseAction(),
        VueInstance.$t('t.Promise'),
        VueInstance.$t('t.Promise'),
        VueInstance.$icon('i.Promises')
      )
        .setCategory(Action.category.CREATE)
        .addRule((a, b) => a.extra.currency === b.extra.currency && this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
        .skipConfirmation()
      )
    }

    if (
      VueInstance.$store.getters.currentUserHasPermission('CanChangeInvoiceState')
    ) {
      actions.add(new Action(
        'exclude-from-dunning',
        this._buildSetInvoicePropertiesAction('excludeFromDunning', true),
        VueInstance.$t('t.DunningDisabled'),
        VueInstance.$t('t.TheDunning')
      )
        .setCategory(Action.category.EDIT)
        .rulesAreInclusives()
        .addRule(a => a.extra?.excludeFromDunning === false)
      )
      actions.add(new Action(
        'include-from-dunning',
        this._buildSetInvoicePropertiesAction('excludeFromDunning', false),
        VueInstance.$t('t.DunningActivated'),
        VueInstance.$t('t.TheDunning')
      )
        .setCategory(Action.category.EDIT)
        .rulesAreInclusives()
        .addRule(a => a.extra?.excludeFromDunning === true)
      )
    }

    if (
      VueInstance.$store.getters.currentUserHasPermission('CanChangeTransactionType')
    ) {
      actions.add(new Action(
        'set-transaction-type',
        this._buildSetInvoicePropertiesAction('transactionTypeId'),
        VueInstance.$t('t.TransactionType'),
        VueInstance.$t('t.TheTransactionType')
      )
        .setCategory(Action.category.EDIT)
        .addAcceptedValue({
          type: { documentType: 'transaction-types' },
          label: VueInstance.$t('t.TransactionType'),
          placeholder: VueInstance.$t('t.None')
        })
      )
    }

    if (
      VueInstance.$store.getters.currentUserHasPermission('InvoiceDetails')
    ) {
      actions.add(new Action(
        'set-invoice-comment',
        this._buildSetInvoicePropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
    }

    if (VueInstance.$store.getters.currentUserHasPermission('Messages')) {
      actions.add(new Action(
        'message',
        this._buildMessageAction(),
        VueInstance.$t('t.Message'),
        VueInstance.$t('t.Message'),
        VueInstance.$icon('i.NewEmail')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: {
            documentType: 'templates',
            filters: { 'template-types': new IncExc().include(['reminder', 'account-full-message']) },
            isRequired: true
          },
          label: VueInstance.$t('t.Template')
        })
        .addRule((a, b) => this._ruleSameAccount(a, b))
      )
    }

    actions.add(new Action(
      'topic-invoice',
      this._buildTopicAction(),
      VueInstance.$t('t.Topic'),
      VueInstance.$t('t.Topic'),
      VueInstance.$icon('i.Topics')
    )
      .setCategory(Action.category.CREATE)
      .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
      .skipConfirmation()
    )

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableLabelActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'delete-label',
      this._buildDeleteAction('hard'),
      VueInstance.$t('t.DeletePermanently'),
      VueInstance.$t('t.DeletePermanently'),
      VueInstance.$icon('i.HardDelete')
    )
      .cannotBeQuick()
      .setSelectionMustBeCleared()
    )

    return actions
  }

  /**
   * @private
   * @param {RawItem} item
   * @returns {ActionList}
   */
  _getAvailableLinkActions (item) {
    const actions = new ActionList()

    actions.add(new Action(
      'link',
      this._buildLinkAction(true),
      VueInstance.$t('t.Link'),
      VueInstance.$t('t.Link'),
      VueInstance.$icon('i.Link')
    )
      .setRevertAction(this._buildLinkAction(item.linked.value, item.linked.grantUsersOf))
      .rulesAreInclusives()
      .addRule(a => a.linked && (!a.linked.value || a.linked.grantUsersOf))
    )

    actions.add(new Action(
      'link-grant-users',
      this._buildLinkAction(true, true),
      VueInstance.$t('t.LinkGrantUsers'),
      VueInstance.$t('t.LinkGrantUsers'),
      VueInstance.$icon('i.LinkGrantUsers')
    )
      .setRevertAction(this._buildLinkAction(item.linked.value, item.linked.grantUsersOf))
      .rulesAreInclusives()
      .addRule(a => {
        return ![
          'groupama-requests',
          'messages-inbox',
          'messages-outbox',
          'messages-unassigned'
        ].includes(a.type) &&
          a.type !== this.#search.linkTarget.type && // This cover the case TopicTopic
          a.linked &&
          (!a.linked.value || !a.linked.grantUsersOf)
      })
    )

    actions.add(new Action(
      'unlink',
      this._buildLinkAction(false),
      VueInstance.$t('t.Unlink'),
      VueInstance.$t('t.Unlink'),
      VueInstance.$icon('i.LinkOff')
    )
      .setRevertAction(this._buildLinkAction(item.linked.value, item.linked.grantUsersOf))
      .rulesAreInclusives()
      .addRule(a => a.linked && a.linked.value)
    )

    return actions
  }

  /**
   * @private
   * @param {RawItem} item
   * @returns {ActionList}
   */
  _getAvailableMessageActions (item) {
    const actions = new ActionList()

    if (['messages-inbox', 'messages-outbox'].includes(this.#search.documentType)) {
      actions.add(new Action(
        'topic-message',
        this._buildTopicAction(),
        VueInstance.$t('t.Topic'),
        VueInstance.$t('t.Topic'),
        VueInstance.$icon('i.Topics')
      )
        .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
        .skipConfirmation()
      )

      actions.add(new Action(
        'set-message-labels-link',
        this._buildSetLabelsLinkAction(),
        VueInstance.$t('t.Label'),
        VueInstance.$t('t.Label'),
        VueInstance.$icon('i.Labels')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'label',
          label: VueInstance.$t('t.Label')
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.Delete')
        })
      )
    }

    if (!item.extra.isDeleted) {
      const [deleteAll, deleteSelection] = item.type === 'messages-unassigned'
        ? ['CanDeleteAllUnassignedMessages', 'CanDeleteUnassignedMessages']
        : ['CanDeleteAllMessage', 'CanDeleteMessage']

      const soft = new Action(
        'soft-delete-message',
        this._buildDeleteAction('soft'),
        VueInstance.$t('t.Delete'),
        VueInstance.$t('t.Delete'),
        VueInstance.$icon('i.Delete')
      ).setRevertAction(this._buildDeleteAction('restore'))

      if (!VueInstance.$store.getters.currentUserHasPermission(deleteAll)) {
        soft.addRule(
          () => VueInstance.$store.getters.currentUserHasPermission(deleteSelection)
        )
      }
      actions.add(soft)
    }

    if (item.extra.isDeleted) {
      const restore = new Action(
        'restore-delete-message',
        this._buildDeleteAction('restore'),
        VueInstance.$t('t.UnDelete'),
        VueInstance.$t('t.UnDelete'),
        VueInstance.$icon('i.NotDelete')
      )
        .setRevertAction(this._buildDeleteAction('soft'))

      if (!VueInstance.$store.getters.currentUserHasPermission('CancelRemoveAllMessages')) {
        restore.addRule(
          () => VueInstance.$store.getters.currentUserHasPermission('CancelRemoveSelectionMessages')
        )
      }
      actions.add(restore)

      const hard = new Action(
        'hard-delete-message',
        this._buildDeleteAction('hard'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$icon('i.HardDelete')
      )
        .cannotBeQuick()
        .setSelectionMustBeCleared()

      if (!VueInstance.$store.getters.currentUserHasPermission('CanDeleteAllDeletedMessages')) {
        hard.addRule(
          () => VueInstance.$store.getters.currentUserHasPermission('CanDeleteDeletedMessages')
        )
      }
      actions.add(hard)
    }

    return actions
  }

  /**
    * @private
    * @returns {ActionList}
    */
  _getAvailableProcessActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'reorder-process',
      this._buildReorder('processes'),
      VueInstance.$t('t.ReorderProcesses'),
      VueInstance.$t('t.ReorderProcesses')
    )
      .addAcceptedValue({
        type: 'select',
        label: VueInstance.$t('t.Direction'),
        values: [
          { text: VueInstance.$t('t.Before'), value: 'before' },
          { text: VueInstance.$t('t.After'), value: 'after' }
        ]
      })
      .addAcceptedValue({
        type: { isRequired: true, documentType: 'processes' },
        excluded: () => this.#search.selectedItems.includeItems.map(i => i.id),
        label: VueInstance.$t('t.Process')
      })
    )

    actions.add(new Action(
      'execute-processes',
      this._buildExecuteProcesses(),
      VueInstance.$t('t.ExecuteProcesses'),
      VueInstance.$t('t.ExecuteProcesses')
    ))

    return actions
  }

  /**
    * @private
    * @returns {ActionList}
    */
  _getAvailablePromiseActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'topic-promise',
      this._buildTopicAction(),
      VueInstance.$t('t.Topic'),
      VueInstance.$t('t.Topic'),
      VueInstance.$icon('i.Topics')
    )
      .addRule((a, b) => this._ruleSameAccount(a, b) && this.#search.selectedItems.size.total <= MAX_SELECTED_ITEMS)
      .skipConfirmation()
    )

    actions.add(new Action(
      'set-promise-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.Label'),
      VueInstance.$icon('i.Labels')
    )
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    if (
      VueInstance.$store.getters.currentUserHasPermission('Promises')
    ) {
      actions.add(new Action(
        'set-promise-comment',
        this._buildSetPromisePropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        // .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
    }

    return actions
  }

  /**
  * @private
  * @returns {ActionList}
  */
  _getAvailableAllocationAnnouncementActions () {
    const actions = new ActionList()

    if (
      VueInstance.$store.getters.currentUserHasPermission('Announcements')
    ) {
      actions.add(new Action(
        'set-allocation-announcement-comment',
        this._buildSetAllocationAnnouncementPropertiesAction('comment'),
        VueInstance.$t('t.Comment'),
        VueInstance.$t('t.EditComment'),
        VueInstance.$icon('i.Comment')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comment'),
          counter: 2000,
          default: () => null
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.RemoveOldComments')
        })
      )
    }

    return actions
  }

  /**
   * @private
   * @param {RawItem} item
   * @returns {ActionList}
   */
  _getAvailableReminderActions (item) {
    // const validationMode = this.#search.documentType === 'calendar-based-reminders'
    //   ? (VueInstance.$store.getters.currentUser?.settings?.calendarManualValidationMode ?? false)
    //   : (VueInstance.$store.getters.currentUser?.settings?.dunningManualValidationMode ?? false)

    const actions = new ActionList()

    if (!['sent', 'rejected'].includes(item.extra.status)) {
      actions.add(new Action(
        'include-dunning',
        this._buildSetReminderStatusAction('proposed'),
        VueInstance.$t('t.Include'),
        VueInstance.$t('t.Include')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.status === 'neutral' || a.extra.status === 'excluded')
        .setSelectionMustBeCleared()
      )
      actions.add(new Action(
        'exclude-dunning',
        this._buildSetReminderStatusAction('excluded'),
        VueInstance.$t('t.Exclude'),
        VueInstance.$t('t.Exclude')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.status === 'neutral' || a.extra.status === 'proposed')
        .setSelectionMustBeCleared()
      )
    }

    if (item.extra.status === 'sent') {
      actions.add(new Action(
        'download-letter',
        this._buildDownloadLetterAction(),
        VueInstance.$t('t.DownloadLetters'),
        VueInstance.$t('t.DownloadLetters')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.toDownload)
      )
      actions.add(new Action(
        'reject-dunning',
        this._buildSetReminderStatusAction('rejected'),
        VueInstance.$t('t.Reject'),
        VueInstance.$t('t.Reject')
      )
        .cannotBeQuick()
        .addRule(a => this.#search.documentType === 'dunning-reminders' && (a.extra.toDownload || (a.extra.type === 'ar24' && !a.extra.ar24MessageStatus) || (a.extra.type === 'maileva' && !a.extra.mailevaLetterStatus)))
      )

      // const scope = Scope.account(item.extra.accountId)
      actions.add(new Action(
        'send-ar24-message',
        this._buildSendAr24Message(),
        VueInstance.$t('t.SendAr24Message'),
        VueInstance.$t('t.SendAr24Message')
      )
        .cannotBeQuick()
        .addRule(a => this.#search.documentType === 'dunning-reminders' && a.extra.canBeSendByAr24 && this.#search.selectedItems.size.include === 1)
        .addAcceptedValue({
          type: {
            isRequired: true,
            documentType: 'account-contacts',
            scope: () => Scope.account(this.#search.selectedItems.includeItems[0].extra.accountId),
            hasEmail: true
          },
          label: VueInstance.$t('t.To')
        })
      )

      actions.add(new Action(
        'sync-ar24-message',
        this._buildSyncAr24Message(),
        VueInstance.$t('t.SyncAr24Message'),
        VueInstance.$t('t.SyncAr24Message')
      )
        .cannotBeQuick()
        .addRule(a => this.#search.documentType === 'dunning-reminders' && a.extra.type === 'ar24' && (a.extra.ar24MessageStatus === 'ev' || a.extra.ar24MessageStatus === 'waiting') && this.#search.selectedItems.size.include === 1)
        .skipConfirmation()
      )
    }

    actions.add(new Action(
      'send-maileva-letter',
      this._buildSendMailevaLetter(),
      VueInstance.$t('t.SendMailevaLetter'),
      VueInstance.$t('t.SendMailevaLetter')
    )
      .cannotBeQuick()
      .addRule(a => this.#search.documentType === 'dunning-reminders' && a.extra.canBeSendByMaileva)
    )

    actions.add(new Action(
      'sync-maileva-letter',
      this._buildSyncMailevaLetter(),
      VueInstance.$t('t.SyncMailevaLetter'),
      VueInstance.$t('t.SyncMailevaLetter')
    )
      .cannotBeQuick()
      .addRule(a => this.#search.documentType === 'dunning-reminders' && a.extra.type === 'maileva' && a.extra.mailevaLetterStatus && a.extra.mailevaLetterStatus !== 'processed' && a.extra.mailevaLetterStatus !== 'rejected' && a.extra.mailevaLetterStatus !== 'processedWithErrors')
      .skipConfirmation()
    )

    actions.add(new Action(
      'preview-dunning',
      this._buildPreviewAction(),
      VueInstance.$t('t.Preview'),
      VueInstance.$t('t.Preview'),
      VueInstance.$icon('i.Eye')
    ).cannotBeBulkAction()
    )

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableTopicActions () {
    const actions = new ActionList()

    actions.add(new Action(
      'close-topic',
      this._buildSetTopicPropertiesAction(true),
      VueInstance.$t('t.Close'),
      VueInstance.$t('t.Close'),
      VueInstance.$icon('i.Lock')
    )
      .rulesAreInclusives()
      .addRule(a => a.extra.isOpen)
    )

    actions.add(new Action(
      'unclose-topic',
      this._buildSetTopicPropertiesAction(false),
      VueInstance.$t('t.Unclose'),
      VueInstance.$t('t.Unclose'),
      VueInstance.$icon('i.Unlock')
    )
      .rulesAreInclusives()
      .addRule(a => !a.extra.isOpen)
    )

    if (VueInstance.$store.getters.isMasterAdmin() || VueInstance.$store.getters.currentUserHasPermission('NoteCanDelete')) {
      actions.add(new Action(
        'delete-topic',
        this._buildDeleteAction('hard'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$icon('i.HardDelete')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => !a.extra.isOpen)
        .setSelectionMustBeCleared()
      )
    }

    actions.add(new Action(
      'set-topic-labels-link',
      this._buildSetLabelsLinkAction(),
      VueInstance.$t('t.Label'),
      VueInstance.$t('t.Label'),
      VueInstance.$icon('i.Labels')
    )
      .addAcceptedValue({
        type: 'label',
        label: VueInstance.$t('t.Label')
      })
      .addAcceptedValue({
        type: 'boolean',
        label: VueInstance.$t('t.Delete')
      })
    )

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableUserActions () {
    const actions = new ActionList()

    if (VueInstance.$store.getters.currentUserHasPermission('UserSettings')) {
      actions.add(new Action(
        'activate-user',
        this._buildSetUserPropertiesAction('isActive', true),
        VueInstance.$t('t.Activate'),
        VueInstance.$t('t.Activate')
      )
        .setCategory(Action.category.ACTIVATION)
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => !a.extra.isActive)
      )
      actions.add(new Action(
        'deactivate-user',
        this._buildSetUserPropertiesAction('isActive', false),
        VueInstance.$t('t.Deactivate'),
        VueInstance.$t('t.Deactivate')
      )
        .setCategory(Action.category.ACTIVATION)
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.isActive)
      )
      actions.add(new Action(
        'user-is-import-overwritable',
        this._buildSetUserPropertiesAction('isImportOverwritable', true),
        VueInstance.$t('t.IsImportOverwritable'),
        VueInstance.$t('t.IsImportOverwritable')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => !a.extra.isImportOverwritable)
      )
      actions.add(new Action(
        'user-is-not-import-overwritable',
        this._buildSetUserPropertiesAction('isImportOverwritable', false),
        VueInstance.$t('t.IsNotImportOverwritable'),
        VueInstance.$t('t.IsNotImportOverwritable')
      )
        .cannotBeQuick()
        .rulesAreInclusives()
        .addRule(a => a.extra.isImportOverwritable)
      )
      actions.add(new Action(
        'set-user-labels-link',
        this._buildSetLabelsLinkAction(),
        VueInstance.$t('t.Label'),
        VueInstance.$t('t.Label'),
        VueInstance.$icon('i.Labels')
      )
        .setCategory(Action.category.CREATE)
        .addAcceptedValue({
          type: 'label',
          label: VueInstance.$t('t.Label')
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.Delete')
        })
      )
    }

    if (VueInstance.$store.getters.isMasterAdmin()) {
      actions.add(new Action(
        'delete-user',
        this._buildDeleteAction('hard'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$icon('i.HardDelete')
      )
        .cannotBeQuick()
        .addRule(a => !a.extra.isActive)
        .setSelectionMustBeCleared()
      )
    }

    return actions
  }

  /**
   * @private
   * @param {RawItem} item
   * @returns {ActionList}
   */
  _getAvailableWorkItemActions (item) {
    const actions = new ActionList()
    const closeAction = new Action(
      'complete-task',
      this._buildSetWorkItemsAction('close'),
      VueInstance.$t('t.CloseToDo'),
      VueInstance.$t('t.CloseToDo')
    )
      .addRule((a, b) => (!a.extra.isClosed && a.extra.accountId === b.extra.accountId))
      .addAcceptedValue({
        type: 'text',
        label: VueInstance.$t('t.Comments')
      })

    if (VueInstance.$store.getters.currentUserHasPermission('WorkItemsShowBulkAction')) {
      closeAction.rulesAreInclusives()
    }
    actions.add(closeAction)

    if (this.#search.scope.type === Scope.ACCOUNT) {
      const scope = () => Scope.account(item.extra.accountId)
      actions.add(new Action(
        'reassign-task',
        this._buildSetWorkItemsAction('reassign'),
        VueInstance.$t('t.Reassign'),
        VueInstance.$t('t.Reassign')
      )
        .addRule(a => !a.extra.isClosed)
        .addRule((a, b) => a.extra.accountId === b.extra.accountId)
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'users', scope },
          label: VueInstance.$t('t.AssignTo')
        })
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comments')
        })
      )
      actions.add(new Action(
        'report-task',
        this._buildSetWorkItemsAction('report'),
        VueInstance.$t('t.Report'),
        VueInstance.$t('t.Report')
      )
        .addRule(a => !a.extra.isClosed)
        .addRule((a, b) => a.extra.accountId === b.extra.accountId)
        .addAcceptedValue({
          type: 'date',
          label: VueInstance.$t('t.TargetDate'),
          min: moment().add(1, 'days').format('YYYY-MM-DD')
        })
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'work-item-priorities' },
          default: () => 'normal',
          label: VueInstance.$t('t.Priority')
        })
        .addAcceptedValue({
          type: { isRequired: true, documentType: 'users', scope },
          default: () => {
            const userIds = _.uniq(this.#search.selectedItems.includeItems.map(i => i.extra.userId).filter(id => id))
            return userIds.length === 1 ? userIds[0] : undefined
          },
          label: VueInstance.$t('t.AssignTo')
        })
        .addAcceptedValue({
          type: 'boolean',
          label: VueInstance.$t('t.AllowDocumentToBlockTheProtocol')
        })
        .addAcceptedValue({
          type: 'text',
          label: VueInstance.$t('t.Comments')
        })
      )
    }

    return actions
  }

  /**
   * @private
   * @returns {ActionList}
   */
  _getAvailableWorkQueueActions () {
    const actions = new ActionList()

    if (VueInstance.$store.getters.isMasterAdmin()) {
      actions.add(new Action(
        'delete-workqueue',
        this._buildDeleteAction('hard'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$t('t.DeletePermanently'),
        VueInstance.$icon('i.HardDelete')
      )
        .cannotBeQuick()
        .setSelectionMustBeCleared()
      )
    }

    return actions
  }

  /**
   * @private
   * @param {ActionList} actions
   * @returns {Map.<string, Action>}
   */
  _getOrCreateActions (actions) {
    const availableActions = new Map()

    for (const [key, action] of actions.list.entries()) {
      if (!this.#knownActions.has(key)) {
        this.#knownActions.set(key, action)
      }

      availableActions.set(key, this.#knownActions.get(key))
    }

    return availableActions
  }

  /**
   * @private
   * @param {string} actionName
   * @param {ActionHandler} handler
   * @returns {ActionHandler}
   */
  _getOrCreateHandler (actionName, handler) {
    if (!this.#knownHandlers.has(actionName)) {
      this.#knownHandlers.set(actionName, handler)
    }
    return this.#knownHandlers.get(actionName)
  }

  /**
   * Return true if a single accountId can be determined
   * @private
   * @param {RawItem} a
   * @param {RawItem} b
   * @returns {boolean}
   */
  _ruleSameAccount (a, b) {
    return !!VueInstance.$route.params.id || a.extra.accountId === b.extra.accountId
  }

  /**
   * Build a search action
   * @param {SearchAction} action
   * @param {Object} json
   * @param {{item?: RawItem, search?: Search}} target
   * @param {Object} axiosConfig
   * @returns {Promise<*>}
   */
  _searchActionBuilder (action, json, target, axiosConfig) {
    const search = new Search(action).logRun(this.#logRun)
    if (!target.item) {
      search.setMainSearch(target.search ?? this.#search)
    } else {
      search.searchedIds.clear().include([{ id: target.item.id, type: target.item.type }])
      search.searchedDocumentTypes.include([target.item.type])
    }

    return search.executeWithJSON(json, axiosConfig)
  }

  /**
   * @param {RawItem} item
   * @returns {Map.<string, Action>}
   */
  getActions (item) {
    let actions = new ActionList()
    if (this.#search.linkTarget) {
      return this._getOrCreateActions(this._getAvailableLinkActions(item))
    }
    switch (this.#search.documentType) {
      case 'accounts':
        actions = this._getAvailableAccountActions()
        break
      case 'account-contacts':
        actions = this._getAvailableAccountContactsActions()
        break
      case 'allocation-announcements':
        actions = this._getAvailableAllocationAnnouncementActions()
        break
      case 'user-accounts':
        actions = this._getAvailableUserAccountsActions()
        break
      case 'calendar-based-reminders':
      case 'dunning-reminders':
        actions = this._getAvailableReminderActions(item)
        break
      case 'collaboration-tasks':
        actions = this._getAvailableCollaborationTaskActions()
        break
      case 'columns':
        actions = this._getAvailableColumnsActions()
        break
      case 'disputes':
        actions = this._getAvailableDisputeActions()
        break
      case 'escalation-protocols':
        actions = this._getAvailableEscalationProtocolActions()
        break
      case 'groupama-requests':
        actions = this._getAvailableGroupamaRequestActions()
        break
      case 'invoices':
        actions = this._getAvailableInvoiceActions(item)
        break
      case 'label-groups':
      case 'labels':
        actions = this._getAvailableLabelActions()
        break
      case 'messages-inbox':
      case 'messages-outbox':
      case 'messages-unassigned':
        actions = this._getAvailableMessageActions(item)
        break
      case 'processes':
        actions = this._getAvailableProcessActions()
        break
      case 'promises':
        actions = this._getAvailablePromiseActions()
        break
      case 'topics':
        actions = this._getAvailableTopicActions()
        break
      case 'users':
        actions = this._getAvailableUserActions()
        break
      case 'work-items':
        actions = this._getAvailableWorkItemActions(item)
        break
      case 'work-queues':
        actions = this._getAvailableWorkQueueActions()
        break
    }

    return this._getOrCreateActions(actions)
  }
}

class ActionList {
  #list = new Map()

  /** @param {Action} action */
  add (action) {
    this.#list.set(action.code, action)
  }

  get list () { return this.#list }
}
